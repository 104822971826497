import * as React from 'react';
import './App.css';
import { BreaksStore } from './stores';
import { ISerie } from './interfaces/ISerie';
import { inject, observer } from 'mobx-react';
import Frontpage from './components/frontpage/Frontpage';
import VideoPlayer from './components/videoPlayer/VideoPlayer';
import ExerciseSelector from './components/exerciseSelector/ExerciseSelector';
import SerieCreator from './components/serieCreator/SerieCreator';
import SubscriptionService from './services/SubscriptionService';
import Shop from './components/shop/Shop';

export interface IAppProps {
  breaksStore?: BreaksStore;
}

export interface IAppState {
  selectedSerie: ISerie;
  selectedCustomSerie: ISerie;
  frontpageOpacity: number;
}

@inject('breaksStore')
@observer
export default class App extends React.Component<IAppProps, IAppState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedSerie: undefined,
      selectedCustomSerie: undefined,
      frontpageOpacity: 1
    }
    if (localStorage.getItem("hasAccess")) {
      this.props.breaksStore.hasAccess = true;
    }
    if (location.search.indexOf("shop") !== -1) {
      this.props.breaksStore.showShop = true;
    } else {
      this.props.breaksStore.loadCustomSeries();
    }
    window.addEventListener('resize', () => {
      this.resize()
    });
    this.resize();
  }

  public componentWillMount(): void {
    let scale = (screen.width / 1024) - 0.1;
    if (screen.width < 1024) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + screen.width + ', initial-scale=' + this.round(scale, 1) + ', maximum-scale=' + this.round(scale, 1) + ', user-scalable=no');
    }
    // iPhone 5s
    if (window.devicePixelRatio === 2 && screen.height === 568) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 568 + ', initial-scale=' + 0.5 + ', maximum-scale=' + 0.5 + ', user-scalable=no');
      this.props.breaksStore.isPhone = true;
    }
    // iPhone 6 / iPhone 7 / iPhone 8
    if (window.devicePixelRatio === 2 && screen.height === 667) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 667 + ', initial-scale=' + 0.6 + ', maximum-scale=' + 0.6 + ', user-scalable=no');
      this.props.breaksStore.isPhone = true;
    }
    // iPhone 6 plus / iPhone7 plus / iPhone8 plus
    if (window.devicePixelRatio === 3 && screen.height === 736) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 736 + ', initial-scale=' + 0.6 + ', maximum-scale=' + 0.6 + ', user-scalable=no');
      this.props.breaksStore.isPhone = true;
    }
    // iPhone X
    if (window.devicePixelRatio === 3 && screen.height === 812) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 812 + ', initial-scale=' + 0.5 + ', maximum-scale=' + 0.5 + ', user-scalable=no');
      this.props.breaksStore.isPhone = true;
    }
    // iPhone XS max
    if (window.devicePixelRatio === 3 && screen.height === 896) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 896 + ', initial-scale=' + 0.6 + ', maximum-scale=' + 0.6 + ', user-scalable=no');
      this.props.breaksStore.isPhone = true;
    }
    // iPad 6th gen. / iPad air 2 / 
    if (window.devicePixelRatio === 2 && screen.height === 1024) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 1024 + ', initial-scale=' + 1.0 + ', maximum-scale=' + 1.0 + ', user-scalable=no');
    }
    // iPad pro 10.5 inch
    if (window.devicePixelRatio === 2 && screen.height === 1112) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 1112 + ', initial-scale=' + 1.0 + ', maximum-scale=' + 1.0 + ', user-scalable=no');
    }
    // iPad pro 11 inch
    if (window.devicePixelRatio === 2 && screen.height === 1194) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 1194 + ', initial-scale=' + 1.0 + ', maximum-scale=' + 1.0 + ', user-scalable=no');
    }
    // iPad pro 12.9 inch
    if (window.devicePixelRatio === 2 && screen.height === 1366) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 1024 + ', initial-scale=' + 1.2 + ', maximum-scale=' + 1.2 + ', user-scalable=no');
    }
    // iPhone XR
    if (window.devicePixelRatio === 2 && screen.height === 896) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'viewport-fit=cover, width=' + 896 + ', initial-scale=' + 0.6 + ', maximum-scale=' + 0.6 + ', user-scalable=no');
    }
  } round(value, precision): number {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public componentDidMount(): void {
    this.resize();
    setTimeout(() => {
      this.props.breaksStore.showFrontpage = true;
    }, 500);
  }

  private resize(): void {
    const rootDiv: any = document.getElementById("root");
    if (rootDiv) {
      this.props.breaksStore.width = rootDiv.clientWidth;
    }
  }

  render() {
    return (
      <div>
        {this.props.breaksStore.showShop ?
          <Shop />
          :
          <div>
            {this.props.breaksStore.showFrontpage &&
              <div
                className="menuWrapper"
                style={{
                  width: this.props.breaksStore.width * 3,
                  left: this.props.breaksStore.width * -this.props.breaksStore.menuIndex
                }}>
                {!this.props.breaksStore.showLoader &&
                  <SerieCreator
                    back={() => {
                      this.props.breaksStore.menuIndex++;
                      setTimeout(() => {
                        this.props.breaksStore.isCreatingSerie = false;
                        this.props.breaksStore.isSaving = false;
                        this.render();
                      }, 500);
                    }}
                  />
                }
                {!this.props.breaksStore.selectedExercise != undefined &&
                  <Frontpage />
                }
              </div>
            }
            {this.props.breaksStore.selectedExercise != undefined &&
              <VideoPlayer />
            }
            {!this.props.breaksStore.removeLoader &&
              <div
                className="loader"
                style={{
                  opacity: this.props.breaksStore.showLoader ? 1 : 0
                }}
              />
            }
          </div>
        }
      </div>
    );
  }

}