import * as React from 'react';
import './ExerciseSelector.css';
// import ReactPlayer from 'react-player';
import Localization from '../localization/Localization';
import { IExercise } from '../../interfaces/IExercise';
import ExerciseButton from './exerciseButton/ExerciseButton';
import ExerciseFilter from './ExerciseFilter/ExerciseFilter';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';
import AccessPopup from '../accessPopup/AccessPopup';

export interface IExerciseSelectorProps {
  breaksStore?: BreaksStore;
  style?: React.CSSProperties;
  onSelect: (selectedExercise: IExercise) => void;
  back?: () => void;
  close?: () => void;
}

export interface IExerciseSelectorState {
  showFilter: boolean;
  selectedExercise: IExercise;
  selectedTags: string[];
}

@inject('breaksStore')
@observer
export default class ExerciseSelector extends React.Component<IExerciseSelectorProps, IExerciseSelectorState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showFilter: false,
      selectedExercise: undefined,
      selectedTags: []
    }
  }

  render() {
    let exercises: JSX.Element[] = [];
    let isDanish = navigator.language == "da" || navigator.language == "da-DK" || navigator.language == "da-dk";
    this.props.breaksStore.exercises.forEach((exercise: IExercise, index) => {
      if (exercise.type == "mindful" && !isDanish) {
      } else {
        if (this.state.selectedTags == undefined || this.state.selectedTags.length == 0) {
          exercises.push(
            <ExerciseButton
              key={exercise.id}
              exercise={exercise}
              index={index}
              showAddButton
              onSelect={(selectedExercise: IExercise, index: number) => {
                this.props.breaksStore.selectedExercise = selectedExercise;
              }}
              add={(selectedExercise: IExercise) => this.props.onSelect(selectedExercise)}
              showAccessPopup={() => this.props.breaksStore.showAccessPopup = true}
            />
          );
        } else {
          let exerciseIsTaggedWithSelectedTag: boolean = false;
          this.state.selectedTags.forEach((tag: string) => {
            if (exercise.tags && exercise.tags.length > 0) {
              exercise.tags.forEach((exerciseTag: string) => {
                if (tag == exerciseTag) {
                  exerciseIsTaggedWithSelectedTag = true;
                }
              });
            }
          });
          if (exerciseIsTaggedWithSelectedTag) {
            exercises.push(
              <ExerciseButton
                key={exercise.id}
                exercise={exercise}
                index={index}
                showAddButton
                onSelect={(selectedExercise: IExercise, index: number) => {
                  this.props.breaksStore.selectedExercise = selectedExercise;
                }}
                add={(selectedExercise: IExercise) => this.props.onSelect(selectedExercise)}
                showAccessPopup={() => this.props.breaksStore.showAccessPopup = true}
              />
            );
          }
        }
      }
    });
    let numberOfCokumns = Math.floor((window.innerWidth - 80) / 200);
    let padding = (window.innerWidth - (200 * numberOfCokumns)) / 2;
    return !this.state.showFilter ?
      <div
        className="exerciseSelector"
        style={{
          backgroundImage: `url(http://app.happybreaks.dk/images/exercises-bg.jpg)`,
          ...this.props.style
        }}
      >
        <div className="header">
          <div className="buttonArea">
            {this.props.back != undefined &&
              <div
                className="backButton"
                onClick={() => this.props.back()}
              />
            }
          </div>
          <div
            className="buttonArea"
            style={{
              float: "right"
            }}
          >
            {this.props.close != undefined &&
              <div
                className="closeButton"
                onClick={() => this.props.close()}
              />
            }
          </div>
          <div className="titleArea">{Localization.getString("ExercisesTitle")}</div>
          <div className="headerLine" />
          <div
            className="headerButton"
            style={{
              textAlign: "center",
              float: "left",
              width: this.state.selectedTags == undefined || this.state.selectedTags.length == 0 ? 430 : 460,
              marginLeft: this.state.selectedTags == undefined || this.state.selectedTags.length == 0 ? "calc(50% - 215px)" : "calc(50% - 230px)"
            }}
            onClick={() => this.setState({ showFilter: true })}
          >
            <div className="filterButtonText">
              {this.state.selectedTags == undefined || this.state.selectedTags.length == 0 ? Localization.getString("FilterButton") : "Rediger valgte egenskaber"}
            </div>
            <div className="filterButtonIcon" />
          </div>
        </div>
        <div className="exerciseSelectorWrapper">
          {exercises}
        </div>
        {this.props.breaksStore.showAccessPopup &&
          <AccessPopup />
        }
      </div>
      :
      <ExerciseFilter
        close={() => this.setState({ showFilter: false })}
        updateTags={(selectedTags: string[]) => {
          this.setState({ selectedTags });
        }}
        selectedTags={this.state.selectedTags}
      />
  };
}