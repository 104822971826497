import * as React from 'react';
import './Frontpage.css';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';
import Menu from '../menu/Menu';
import { IExercise } from '../../interfaces/IExercise';
import DeleteCustomSeriePopup from '../deleteCustomSeriePopup/DeleteCustomSeriePopup';
import AccessPopup from '../accessPopup/AccessPopup';
import SubscriptionService from '../../services/SubscriptionService';
import CententService from '../../services/ContentService';
const TodaysHappyBreak = require('../../resources/todays-happy-break.json');

declare global {
  interface Window { webkit: any; app: any; android: WebAppInterface; platform: string }
  interface WebAppInterface { postMessage(message: string): any; }
}

export interface IFrontpageProps {
  breaksStore?: BreaksStore;
}

export interface IFrontpageState {
  todaysHappyBreakOpacity: number;
  products: any[];
}

@inject('breaksStore')
@observer
export default class Frontpage extends React.Component<IFrontpageProps, IFrontpageState> {

  constructor(props) {
    super(props);
    this.detectPlatform();
    this.props.breaksStore.loadCustomSeries();
    this.state = {
      todaysHappyBreakOpacity: 0,
      products: []
    }
  }

  private getTodaysHappyBreakExercise(): void {
    TodaysHappyBreak.forEach((todaysHappyBreak: { dayInMonth: number, exercise: string }) => {
      if (todaysHappyBreak.dayInMonth === new Date().getDate()) {
        this.props.breaksStore.exercises.forEach((exercise: any) => {
          if (exercise.id.toLowerCase() === todaysHappyBreak.exercise.toLowerCase()) {
            this.props.breaksStore.todaysExercise = exercise;
          }
        });
      }
    });
    this.forceUpdate();
  }

  private detectPlatform(): void {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1 && window.android != undefined) {
      window.platform = "android";
    } else if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      window.platform = "ios";
    } else {
      window.platform = "web";
    }
    console.log("Platform: " + window.platform);
  }

  private openFullscreen(): void {
    const elem: any = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
    this.props.breaksStore.fullscreen = true;
  }

  private closeFullscreen(): void {
    let element: any = document;
    if (element.exitFullscreen) {
      element.exitFullscreen();
    } else if (element.webkitExitFullscreen) { /* Safari */
      element.webkitExitFullscreen();
    } else if (element.msExitFullscreen) { /* IE11 */
      element.msExitFullscreen();
    }
    this.props.breaksStore.fullscreen = false;
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.props.breaksStore.showLoader = false;
      setTimeout(() => {
        this.props.breaksStore.removeLoader = true;
      }, 1000);
    }, 2000);
    setInterval(() => {
      this.setState({
        todaysHappyBreakOpacity: 0
      });
      setTimeout(() => {
        this.setState({
          todaysHappyBreakOpacity: 1
        });
      }, 2000);
    }, 4000);
    this.props.breaksStore.showIntroPopup = localStorage.getItem("IntroPopupIsShown") === null;
    if (localStorage.getItem("hasAccess")) {
      SubscriptionService.get(localStorage.getItem("hasAccess")).then((result) => {
        if (result && result.content && result.content.length > 0 && result.content[0].state === "active") {
          this.props.breaksStore.hasAccess = true;
        } else {
          localStorage.removeItem("hasAccess");
          this.props.breaksStore.hasAccess = false;
          this.props.breaksStore.showAccessPopup = true;
        }
      });
    }
    CententService.getJSON("https://perlefilm.s3.eu-north-1.amazonaws.com/breaks/HappyBreaks/resources/exercises1.json").then((exercises: any[]) => {
      CententService.getJSON("https://perlefilm.s3.eu-north-1.amazonaws.com/breaks/HappyBreaks/resources/products1.json").then((products: any[]) => {
        this.props.breaksStore.exercises = exercises;
        this.setState({ products }, () => this.getTodaysHappyBreakExercise());
      });
    });
  }

  render() {
    setTimeout(() => {
      if (this.props.breaksStore.goToMySeries && document.getElementsByClassName("frontpage")[0]) {
        document.getElementsByClassName("frontpage")[0].scrollTop = 20000;
        setTimeout(() => {
          this.props.breaksStore.goToMySeries = false;
        }, 1000);
      }
    }, 1000);

    // Load products
    const products = [];
    const quickAccesses = [];
    quickAccesses.push(
      <div
        className="anchor"
        style={{
          backgroundImage: `url(http://app.happybreaks.dk/images/icons/quickAccess/mineserier.png)`
        }}
        onClick={() => {
          var my_element = document.getElementById("customProduct");
          my_element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }}
      />
    );
    if (this.state.products && this.state.products.length > 0 && this.props.breaksStore.exercises && this.props.breaksStore.exercises.length > 0) {
      this.state.products.forEach((product: any) => {
        const series = [];
        product.series.forEach((serie: any) => {
          const exercises = [];
          exercises.push(
            <div
              className="serieHeader"
              style={{
                backgroundImage: `url(http://app.happybreaks.dk/images/icons/series/${serie.id}.png)`
              }}
              onClick={() => {
                if (serie.product === "pay" && !this.props.breaksStore.hasAccess) {
                  this.props.breaksStore.showAccessPopup = true;
                } else {
                  if (serie.playAll) {
                    this.props.breaksStore.playWholeSerie = true;
                    this.props.breaksStore.exercises.forEach((exercise: any) => {
                      if (exercise.id.toLowerCase() === serie.exercises[0].toLowerCase()) {
                        this.props.breaksStore.playWholeSerie = true;
                        this.props.breaksStore.selectedSerie = serie;
                        this.props.breaksStore.selectedExercise = exercise;
                        this.props.breaksStore.selectedExerciseIndex = 0;
                      }
                    });
                  }
                }
              }}
            >
              {serie.product === "pay" && !this.props.breaksStore.hasAccess &&
                <div className="serieHeaderIconLock" />
              }
            </div>
          );
          serie.loadedExercises = [];
          serie.exercises.forEach((exerciseId: string, index) => {
            this.props.breaksStore.exercises.forEach((exercise: any) => {
              if (exercise.id.toLowerCase() === exerciseId.toLowerCase()) {
                serie.loadedExercises.push(
                  exercise
                );
                exercises.push(
                  <div
                    className="exercise"
                    style={{
                      backgroundImage: `url(http://app.happybreaks.dk/images/icons/${exerciseId}.png)`
                    }}
                    onClick={() => {
                      if (serie.product === "pay" && !this.props.breaksStore.hasAccess) {
                        this.props.breaksStore.showAccessPopup = true;
                      } else {
                        this.props.breaksStore.selectedSerie = serie;
                        this.props.breaksStore.selectedExercise = exercise;
                        this.props.breaksStore.selectedExerciseIndex = index;
                      }
                    }}
                  >
                    {serie.product === "pay" && !this.props.breaksStore.hasAccess &&
                      <div className="exerciseLock" />
                    }
                    <div className="exerciseName">
                      {exercise.danish.name}
                    </div>
                  </div>
                );
              }
            });
          });
          series.push(
            <div
              className="serie"
              id={serie.id}
              onScroll={() => {
                serie.hideArrow = true;
                this.forceUpdate();
              }}
            >
              <div
                className="serieWrapper"
              >
                {exercises}
                <div
                  className="serieArrow"
                  id={`arrow-${serie.id}`}
                  style={{
                    opacity: screen.width < ((serie.exercises.length * 190) + 250) && !serie.hideArrow ? 0.9 : 0
                  }}
                  onClick={() => {
                    document.getElementById(serie.id).scrollTo({
                      top: 0,
                      //left: window.innerWidth - 75,
                      left: serie.exercises.length * 190 + 250,
                      behavior: 'smooth'
                    });
                    document.getElementById('arrowBack-' + serie.id).setAttribute('style', 'opacity: 0.9');
                    document.getElementById('arrow-' + serie.id).setAttribute('style', 'opacity: 0');
                    console.log(serie.exercises.length * 190 + 250)
                  }}

                />

                <div
                  className="serieArrow serieArrowBack"
                  id={`arrowBack-${serie.id}`}
                  style={{
                    opacity: screen.width < ((serie.exercises.length * 190) + 250) && serie.hideArrow ? 0.9 : 0
                  }}
                  onClick={() => {
                    document.getElementById(serie.id).scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    });
                    document.getElementById('arrow-' + serie.id).setAttribute('style', 'opacity: 0.9')
                    document.getElementById('arrowBack-' + serie.id).setAttribute('style', 'opacity: 0')
                  }}
                />
              </div>
            </div>
          );
        });

        products.push(
          <div
            className="productWrapper"
            id={product.type + 'wrapper'}
          >
            {(product.type !== "nyheder_serie" && product.type !== "dagens_mandala" && product.type !== "sov_godt_overskrifte2") &&
              <div
                className="productHeader"
              >
                <div
                  className="productHeaderIcon"
                  style={{
                    backgroundImage: `url(http://app.happybreaks.dk/images/icons/quickAccess/${product.type}.png)`
                  }}
                />
              </div>
            }
            {series}
          </div>
        );
        {
          (product.type !== "nyheder_serie") &&
            quickAccesses.push(
              <div
                className="anchor"
                style={{
                  backgroundImage: `url(http://app.happybreaks.dk/images/icons/quickAccess/${product.type}.png)`
                }}
                onClick={() => {
                  var my_element = document.getElementById(product.type + 'wrapper');
                  my_element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                  });
                }}
              />
            );
        }
      });
    }
    // Load custom series
    const series = [];
    this.props.breaksStore.customSeries.forEach((customSerie: any) => {
      const exercises = [];
      exercises.push(
        <div
          className="serieHeader"
          style={{
            backgroundImage: `url(http://app.happybreaks.dk/images/icons/myserie.png)`
          }}
        >
          <div
            className="serieHeaderText"
            style={{
              lineHeight: customSerie.customName.length < 10 ? "57px" : "30px",
            }}
            onClick={() => {
              this.props.breaksStore.menuIsShowing = false;
              this.props.breaksStore.isCreatingSerie = false;
              this.props.breaksStore.serieToEdit = customSerie;
              setTimeout(() => {
                this.props.breaksStore.menuIndex--;
              }, 500);
            }}
          >
            {customSerie.customName}
          </div>
          <div
            className="customSerieDeleteBtn"
            onClick={() => {
              this.props.breaksStore.customSerieToDelete = customSerie;
              this.forceUpdate();
            }}
          />
        </div>
      );
      customSerie.loadedExercises.forEach((exercise: IExercise, index) => {
        exercises.push(
          <div
            className="exercise"
            style={{
              backgroundImage: `url(http://app.happybreaks.dk/images/icons/${exercise.id}.png)`
            }}
            onClick={() => {
              this.props.breaksStore.selectedSerie = customSerie;
              this.props.breaksStore.selectedExercise = exercise;
              this.props.breaksStore.selectedExerciseIndex = index;
            }}
          >
            <div className="exerciseName">
              {exercise.danish.name}
            </div>
          </div>
        );
      })
      series.push(
        <div
          className="serie"
          onScroll={() => {
            customSerie.hideArrow = true;
            this.forceUpdate();
          }}
        >
          <div
            className="serieWrapper"
          >
            {exercises}
            <div
              className="serieArrow"
              style={{
                opacity: screen.width < ((customSerie.loadedExercises.length * 190) + 250) && !customSerie.hideArrow ? 0.9 : 0
              }}
            />
          </div>
        </div>
      );
    });
    products.push(
      <div
        className="productWrapper"
        id={"customProduct"}
      >
        <div
          className="productHeader"
        >
          <div
            className="productHeaderIcon"
            style={{
              backgroundImage: `url(http://app.happybreaks.dk/images/icons/quickAccess/mineserier.png)`
            }}
            onClick={() => {
              this.props.breaksStore.menuIsShowing = false;
              this.props.breaksStore.isCreatingSerie = true;
              this.props.breaksStore.serieToEdit = {
                type: "custom",
                loadedExercises: [],
                customName: ""
              };
              setTimeout(() => {
                this.props.breaksStore.menuIndex--;
              }, 500);
            }}
          />
          <div
            className="productHeaderIcon"
            style={{
              marginLeft: 10,
              backgroundImage: `url(http://app.happybreaks.dk/images/add-btn.png)`
            }}
            onClick={() => {
              this.props.breaksStore.menuIsShowing = false;
              this.props.breaksStore.isCreatingSerie = true;
              this.props.breaksStore.serieToEdit = {
                type: "custom",
                loadedExercises: [],
                customName: ""
              };
              setTimeout(() => {
                this.props.breaksStore.menuIndex--;
              }, 500);
            }}
          />
        </div>
        {series}
      </div>
    );
    return (
      <div
        className="frontpage"
        style={{
          overflowY: this.props.breaksStore.menuIsShowing ? "hidden" : "scroll"
        }}
      >
        <div
          className="todaysHappyBreak"
          style={{
            visibility: this.props.breaksStore.showLoader ? "hidden" : "visible"
          }}
        >
          <div className="header">
            <div className="logo" />
            <div className="tagline">
              Yoga Breaks, Mindful Breaks, Børnemeditationer og Historier i et eventyrligt filmunivers til børn
            </div>
            {window.platform === "web" &&
              <div
                className="fullScreenBtn"
                onClick={() => {
                  if (this.props.breaksStore.fullscreen) {
                    this.closeFullscreen();
                  } else {
                    this.openFullscreen();
                  }
                }}
              />
            }
            <div
              id={`nav-icon3`}
              className={`${this.props.breaksStore.menuIsShowing ? "open" : ""}`}
              onClick={() => {
                this.props.breaksStore.menuIsShowing = true;
                document.getElementsByClassName("frontpage")[0].scrollTop = 0;
              }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="todaysHappyBreakWrapper"
            style={{
              transform: screen.height < 768 ? "scale(0.5)" : "scale(0.8)",
              marginTop: "calc(50vh - 15vw)"
            }}>
            {/* <div className="menuArrowRight"
              onClick={() => {
                this.props.breaksStore.isCreatingSerie = true;
                this.props.breaksStore.serieToEdit = {
                  type: "custom",
                  loadedExercises: []
                };
                this.props.breaksStore.menuIndex--;
              }}
            />
            <div className="menuArrowLeft"
              onClick={() => {
                this.props.breaksStore.menuIndex++;
              }}
            /> */}
            <div
              className="todaysHappyBreakBobble"
              onClick={() => this.props.breaksStore.selectedExercise = this.props.breaksStore.todaysExercise}
            >
              <div className="todaysHappyBreakBobbleText" />
              <div
                className="todaysHappyBreakBobbleIcon"
                style={{
                  backgroundImage: this.props.breaksStore.todaysExercise ? `url(http://app.happybreaks.dk/images/icons/${this.props.breaksStore.todaysExercise.id}.png)` : "none",
                  opacity: this.state.todaysHappyBreakOpacity
                }}
              >
                <div
                  className="todaysHappyBreakBobbleIcon"
                  style={{
                    backgroundImage: `url(http://app.happybreaks.dk/images/playknap_forside.png)`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="QuickAccess"
          className="quickAccess"
          onScroll={() => {
            this.props.breaksStore.hideQuickAccess = true;
            this.forceUpdate();
          }}
        >
          <div className="quickAccessWrapper">
            {quickAccesses}
          </div>
        </div>
        <div className="quickAccessArrow"
          id={`quickAccessArrow`}
          style={{
            opacity: this.props.breaksStore.hideQuickAccess ? 0 : 0.9
          }}
          onClick={() => {
            document.getElementById("QuickAccess").scrollTo({
              top: 0,
              //left: window.innerWidth - 75,
              left: products.length * 190 + 250,
              behavior: 'smooth'
            });
            document.getElementById('quickAccessArrow').setAttribute('style', 'opacity: 0')
            document.getElementById('quickAccessArrowBack').setAttribute('style', 'opacity: 0.9')
          }}
        />
        <div className="quickAccessArrow quickAccessArrowBack"
          id={`quickAccessArrowBack`}
          style={{
            opacity: this.props.breaksStore.hideQuickAccess ? 0.9 : 0
          }}
          onClick={() => {
            document.getElementById("QuickAccess").scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            document.getElementById('quickAccessArrow').setAttribute('style', 'opacity: 0.9')
            document.getElementById('quickAccessArrowBack').setAttribute('style', 'opacity: 0')
          }}
        />
        <div className="products">
          {products}
        </div>
        <Menu />
        {this.props.breaksStore.customSerieToDelete !== undefined &&
          <DeleteCustomSeriePopup />
        }
        {this.props.breaksStore.showAccessPopup &&
          <AccessPopup />
        }
        {/* {this.props.breaksStore.showIntroPopup &&
          <IntroPopup />
        } */}
      </div>
    );
  }

}