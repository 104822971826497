import { Promise } from 'es6-promise';
import { ISubsricber } from '../interfaces/ISubscriber';

export default class CententService {

  public static getJSON(url: string): Promise<any[]> {
    return new Promise<any[]>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }
}