import { observable, action } from 'mobx';
import { ISerie } from '../interfaces/ISerie';
import { IExercise } from '../interfaces/IExercise';
import { ISubsricber } from '../interfaces/ISubscriber';

export class BreaksStore {

  @observable public width: number;

  @observable public isCreatingSerie: boolean;
  @observable public serieToEdit: ISerie;
  @observable public customSeries: ISerie[];
  @observable public isSaving: boolean;

  @observable public selectedSerie: ISerie;
  @observable public selectedExercise: IExercise;
  @observable public selectedExerciseIndex: number = 0;

  @observable public isPlaying: boolean;
  @observable public isBuffering: boolean;

  @observable public customSerieToDelete: ISerie;
  @observable public playWholeSerie: boolean;
  @observable public showAccessPopup: boolean;
  @observable public email: string;
  @observable public hasAccess: boolean;

  @observable public fullscreen: boolean;

  @observable public goToMySeries: boolean;

  @observable public showLoader: boolean = true;
  @observable public showFrontpage: boolean;

  @observable public showIntroPopup: boolean;

  ///////////////////////////////      Menu      ///////////////////////////////

  @observable public isPhone: boolean;

  ///////////////////////////////      Menu      ///////////////////////////////

  @observable public menuIndex: number = 1;
  @observable public hideQuickAccess: boolean;
  @observable public menuIsShowing: boolean;
  @observable public removeLoader: boolean;

  /////////////////////////////// Video controls ///////////////////////////////

  @action nextExercise(): void {
    this.selectedExerciseIndex++;
    this.selectedExercise = this.selectedSerie.loadedExercises[this.selectedExerciseIndex];
  }

  @action previousExercise(): void {
    this.selectedExerciseIndex--;
    this.selectedExercise = this.selectedSerie.loadedExercises[this.selectedExerciseIndex];
  }

  //////////////////////////////////////////////////////////////////////////////

  @action public saveSerie(): void {
    this.loadCustomSeries();
    if (this.isCreatingSerie) {
      this.customSeries.push(this.serieToEdit);
    } else {
      let indexToEdit = 0;
      this.customSeries.forEach((customSerie: ISerie, index) => {
        if (customSerie.id === this.serieToEdit.id) {
          indexToEdit = index;
        }
      });
      this.customSeries[indexToEdit] = this.serieToEdit;
    }
    localStorage.setItem("customSeries", JSON.stringify(this.customSeries));
    this.goToMySeries = true;
  }

  @action public loadCustomSeries(): void {
    if (localStorage.getItem("customSeries") != undefined) {
      this.customSeries = JSON.parse(localStorage.getItem("customSeries"));
    } else {
      this.customSeries = [];
    }
  }

  @action public deleteSerie(): void {
    let indexToDelete = 0;
    this.customSeries.forEach((customSerie: ISerie, index) => {
      if (customSerie.id === this.customSerieToDelete.id) {
        indexToDelete = index;
      }
    });
    this.customSeries.splice(indexToDelete, 1);
    localStorage.setItem("customSeries", JSON.stringify(this.customSeries));
    this.customSerieToDelete = undefined;
  }

  /////////////////////////////// Shop ///////////////////////////////

  @observable public showShop: boolean;
  @observable public subscriber: ISubsricber;
  @observable public signUpSucces: boolean;
  @observable public notActiveUser: boolean;

  @observable public todaysExercise: any;

  @observable public exercises: any[] = [];
}