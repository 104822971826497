import * as React from 'react';
import './ExerciseFilter.css';
import FilterButton from './filterButton/FilterButton';
import Localization from '../../localization/Localization';
import { IExercise } from '../../../interfaces/IExercise';
const Exercises = require('../../../resources/exercises.json');


export interface IExerciseSelectorProps {
  selectedTags: string[];
  updateTags: (selectedTags: string[]) => void;
  close: () => void;
}

export interface IExerciseSelectorState {
}

export default class ExerciseSelector extends React.Component<IExerciseSelectorProps, IExerciseSelectorState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    let filters: JSX.Element[] = [];
    let tags: string[] = [];
    Exercises.forEach((exercise: IExercise) => {
      if (exercise.tags && exercise.tags.length > 0) {
        exercise.tags.forEach((tag: string) => {
          if (tags.indexOf(tag) === -1) {
            if (tag !== "") {
              tags.push(tag);
            }
          }
        });
      }
    });
    tags = tags.sort();
    tags.forEach((tag: string) => {
      console.log(tag);
      filters.push(
        <FilterButton
          key={tag}
          tag={tag}
          selected={this.props.selectedTags.indexOf(tag) == -1 ? false : true}
          onSelect={(tag: string) => {
            let selectedTags = this.props.selectedTags;
            let existing = undefined;
            selectedTags.forEach((selectedTag: string, index) => {
              if (selectedTag == tag) {
                existing = index;
              }
            });
            if (existing != undefined) {
              selectedTags.splice(existing, 1);
            } else {
              selectedTags.push(tag);
            }
            this.props.updateTags(selectedTags);
          }}
        />
      );
    });
    return (
      <div
        className="exerciseFilter"
        style={{
          backgroundImage: `url(http://app.happybreaks.dk/images/exercises-bg.jpg)`
        }}
      >
        <div className="header">
          <div
            className="buttonArea"
            style={{
              float: "right"
            }}
          >
            {this.props.close != undefined &&
              <div
                className="closeButton"
                onClick={() => this.props.close()}
              />
            }
          </div>
          <div className="titleArea">{Localization.getString("FilterTitle")}</div>
          <div className="headerLine" />
        </div>
        <div className="exerciseFilterWrapper">
          {filters}
        </div>
      </div>
    );
  }
}