import * as React from 'react';
import './SerieCreator.css';
import Localization from '../localization/Localization';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';
import './Save.css';
import ExerciseSelector from '../exerciseSelector/ExerciseSelector';
import { IExercise } from '../../interfaces/IExercise';
import ExerciseButton from '../exerciseSelector/exerciseButton/ExerciseButton';

export interface ISerieCreatorProps {
  breaksStore?: BreaksStore;
  back: () => void;
  // showSeries: () => void;
}

export interface ISerieCreatorState {
  showExercises: boolean;
}

@inject('breaksStore')
@observer
export default class SerieCreator extends React.Component<ISerieCreatorProps, ISerieCreatorState> {

  constructor(props) {
    super(props);
    this.state = {
      showExercises: false
    }
  }

  private makeid(): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  render() {
    let exercises: JSX.Element[] = [];
    let containerWidth: number = 200;
    if (this.props.breaksStore.serieToEdit && this.props.breaksStore.serieToEdit.loadedExercises) {
      this.props.breaksStore.serieToEdit.loadedExercises.forEach((exercise: IExercise, index) => {
        containerWidth += 200;
        exercises.push(
          <ExerciseButton
            key={exercise.id}
            exercise={exercise}
            index={index}
            showDeleteButton
            onSelect={(selectedExercise: IExercise, index: number) => {
              this.props.breaksStore.selectedExercise = selectedExercise;
              // this.props.breaksStore.serieToEdit.loadedExercises.splice(index, 1);
            }}
            onDelete={(index: number) => {
              this.props.breaksStore.serieToEdit.loadedExercises.splice(index, 1);
            }} 
          />
        );
      });
    }
    return (
      (this.state.showExercises ?
        <ExerciseSelector
          onSelect={(selectedExercise: IExercise) => {
            if (this.props.breaksStore.serieToEdit == undefined || this.props.breaksStore.serieToEdit.loadedExercises == undefined) {
              this.props.breaksStore.serieToEdit = {
                type: "custom",
                loadedExercises: []
              };
            }
            this.props.breaksStore.serieToEdit.loadedExercises.push(selectedExercise);
            console.log(selectedExercise);
            this.setState({ showExercises: false });
          }}
          style={{
            left: 0
          }}
          close={() => this.setState({ showExercises: false })}
        />
        :
        <div
          className="serieCreator"
          style={{
            backgroundImage: `url(http://app.happybreaks.dk/images/dreams.jpg)`
          }}
        >
          <div className="header">
            <div
              className="buttonArea"
              style={{
                float: "right"
              }}
            >
              {this.props.back != undefined &&
                <div
                  className="backButton"
                  onClick={() => this.props.back()}
                />
              }
            </div>
            <div
              className="titleArea"
              style={{
                marginLeft: 50,
                width: "calc(100% - 100px)"
              }}
            >
              {this.props.breaksStore.isCreatingSerie ? Localization.getString("SerieCreatorTitle") : Localization.getString("EditSerie")}
            </div>
            <div className="headerLine" />
          </div>
          {this.props.breaksStore.serieToEdit &&
            <div
              className="saveWrapper"
              style={{
                padding: screen.height < 500 ? "px 40px" : "40px",
                top: screen.height < 500 ? "20px" : "calc(30% - 220px)",
              }}
            >
              <h1>{Localization.getString("NameCustomSerie")}</h1>
              <input
                id="CustomTitelInputField"
                type="text"
                placeholder={Localization.getString("TitlePlaceholde")}
                value={this.props.breaksStore.serieToEdit.customName}
                onChange={() => {
                  const inputField: any = document.getElementById("CustomTitelInputField");
                  this.props.breaksStore.serieToEdit.customName = inputField.value;
                }}
              />
            </div>
          }
          <div className="serieCreatorWrapper">
            <div
              className="serieCreatorContainer"
              style={{
                width: containerWidth
              }}
            >

              {exercises}
              <div
                className="addNewButton"
                style={{
                  backgroundImage: `url(http://app.happybreaks.dk/images/add-btn.png)`
                }}
                onClick={() => {
                  this.setState({ showExercises: true });
                }}
              />
            </div>
          </div>
          <div
            className="saveButton"
            onClick={() => {
              this.props.breaksStore.isSaving = true;
              if (this.props.breaksStore.serieToEdit.customName == undefined || this.props.breaksStore.serieToEdit.customName == "") {
                this.props.breaksStore.serieToEdit.customName = Localization.getString("DefaultCustomSerieName");
              }
              this.props.breaksStore.serieToEdit.id = this.makeid();
              this.props.breaksStore.saveSerie();
              this.props.back();
            }}
          >
            {Localization.getString("Save").toUpperCase()}
          </div>
        </div>
      )
    )
  };
}