import * as React from 'react';
import './Menu.css';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';

export interface IMenuPlayerProps {
  breaksStore?: BreaksStore;
}

@inject('breaksStore')
@observer
export default class Menu extends React.Component<IMenuPlayerProps> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      exercise: undefined,
      opacity: 0,
      isPlaying: true
    }
    this.props.breaksStore.isBuffering = true;
  }

  render() {
    return (
      <div
        className="menu"
      >
        <div
          className="menuBg"
          style={{
            visibility: this.props.breaksStore.menuIsShowing ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.breaksStore.menuIsShowing = false;
          }}
        />
        <div
          className="menuBox"
          style={{
            right: this.props.breaksStore.menuIsShowing ? 0 : -280
          }}
        >
          <a href="https://www.happybreaks.dk/om-os" target="_self">
            <div
              className="menuItem"
            >
              Om os
          </div>
          </a>
          <a href="https://app.happybreaks.dk/?shop=true" target="_self">
            <div
              className="menuItem"
            >
              Abonnement
          </div>
          </a>
          <a href="https://www.happybreaks.dk/faq" target="_self">
            <div
              className="menuItem"
            >
              FAQ
          </div>
          </a>
          <a href="https://www.happybreaks.dk/mindful-breaks" target="_self">
            <div
              className="menuItem"
            >
              Mindfulness for børn
          </div>
          </a>
          <a href="https://www.happybreaks.dk/yoga-breaks" target="_self">
            <div
              className="menuItem"
            >
              Børneyoga
          </div>
          </a>
          <a href="https://www.happybreaks.dk/historie-yoga" target="_self">
            <div
              className="menuItem"
            >
              Historieyoga
          </div>
          </a>
          <a href="https://www.happybreaks.dk/komgodtigang" target="_self">
            <div
              className="menuItem"
            >
              Sådan bruger du app'en
          </div>
          </a>
        </div>
      </div>
    );
  }

}