import { Promise } from 'es6-promise';
import { ISubsricber } from '../interfaces/ISubscriber';

export default class SubscriptionService {

  public static get(id: string): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(`https://api.reepay.com/v1/subscription?search=customer.handle:${id},state:active`, {
        // fetch("https://api.reepay.com/v1/customer?size=100&search=text;rhallund@gmail.com", {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Authorization": "Basic cHJpdl8xMWMyMWRhN2RkZjYzOWVhZTMxMmJmZjg3MDIwYjViOTo=",
          "Content-Type": "application/json"
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }

  public static createUser(subscriber: ISubsricber): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch("https://checkout-api.reepay.com/v1/session/recurring", {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Authorization": "Basic cHJpdl8xMWMyMWRhN2RkZjYzOWVhZTMxMmJmZjg3MDIwYjViOTo=",
          "Content-Type": "application/json"
        },
        body: `{
          "create_customer":{
            "handle":"${subscriber.email}",
            "email":"${subscriber.email}",
            "first_name":"${subscriber.first_name}",
            "last_name":"${subscriber.last_name}",
            "vat": "${subscriber.subscription}"
          }
        }`,
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }

  public static addSubscription(plan: string, customerId: string, paymentMethod: string): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(`https://happybreaks-reepay-integration.herokuapp.com/createsubscription/${plan}/sub-${Math.random().toString(36).substring(7)}/${paymentMethod}/${customerId}`, {
        method: "GET",
        credentials: "same-origin",
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }
}