import * as React from 'react';
import './FilterButton.css';
import ReactPlayer from 'react-player';
import Localization from '../../../localization/Localization';

export interface IFilterButtonProps {
  tag: string;
  selected: boolean;
  onSelect: (tag: string) => void;
}

export interface IFilterButtonState {
}

export default class FilterButton extends React.Component<IFilterButtonProps, IFilterButtonState> {

  constructor(props, context) {
    super(props, context);
    this.state = {
    }
  }

  render() {
    return (
      <div 
      className="filterButton"
      onClick={() => this.props.onSelect(this.props.tag)}
      >
        <div
          className="filterButtonCheckbox"
          style={{
            backgroundColor: this.props.selected ? "#fff" : "transparent"
          }}
        />
        {Localization.getString(this.props.tag).toUpperCase()}
      </div>
    );
  }

}