import * as React from 'react';
import './Shop.css';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';
import SubscriptionService from '../../services/SubscriptionService';
import { ISession } from '../../interfaces/ISession';

export interface IShopProps {
  breaksStore?: BreaksStore;
}

@inject('breaksStore')
@observer
export default class Shop extends React.Component<IShopProps> {

  private rp;

  public componentDidMount(): void {
    this.rp = new window.Reepay.ModalCheckout();
  }

  private selectSubscription(plan: any): void {
    this.props.breaksStore.subscriber = {
      "subscription": plan
    };
    var my_element = document.getElementById("UserInfo");
    my_element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  render() {
    return (
      <div className="shop">
        <div className="shopNavBar">
          <div className="title-logo">
            <a href="https://www.happybreaks.dk/">
              <img src="https://static1.squarespace.com/static/5ef374058598437b0fb47e0a/t/5ff86ed0b1f66202d7f6799e/1611321835213/?format=1500w" alt="" />
            </a>
          </div>
          <div
            className="closeBtn"
            onClick={() => this.props.breaksStore.showShop = false}
          />
        </div>

        {this.props.breaksStore.signUpSucces ?
          <div
            className="shopBackground"
            style={{
              height: "calc(100% - 114px)"
            }}
          >
            <div className="shopWrapper">
              <div
                className="shopHeader"
                style={{
                  paddingTop: "20%"
                }}
              >
                <h1>Tillykke med dit abonnement</h1>
                <h3>Du har nu adgang til alle film</h3>
                <h3>God fornøjelse!</h3>
                <div
                  className="subscriptionContactInformationButton"
                  style={{
                    marginLeft: "calc(50% - 90px)"
                  }}
                  onClick={() => this.props.breaksStore.showShop = false}
                >
                  Vend tilbage til app'en
                </div>
              </div>
            </div>
          </div>
          :
          <div>
            <div className="shopBackground">
              <div className="shopWrapper">
                <div className="shopHeader">
                  <h1>Vælg dit abonnement</h1>
                  {/* <div style={{
                    color: "#ffde59",
                    fontSize: 62,
                    letterSpacing: 3,
                    fontWeight: "bold"
                  }}>FORÅRS TILBUD*</div> */}
                  {/* <h3>Velkommen til Happy Breaks! Et helt nyt univers for børn med børnemeditationer, børneyoga, mindfulness for børn og yogahistorier samt sagn og myter.</h3> */}
                </div>
                <div
                  className="subscription"
                  style={{
                    marginLeft: 100,
                    border: this.props.breaksStore.subscriber && this.props.breaksStore.subscriber.subscription === "plan-5729d" ? "4px solid #f6a23c" : "4px solid #eeeeee"
                  }}
                  onClick={() => this.selectSubscription("plan-5729d")}
                >
                  <div style={{
                    height: 200
                  }}>
                    <h3>Årsabonnement</h3>
                    <p><span>Din pris for et år </span>
                      <span style={{
                        textDecoration: "line-through"
                      }}>399,-</span>
                    </p>
                    <div
                      style={{
                        color: "#ffde59",
                        fontSize: 32,
                        fontFamily: "Georgia, Times New Roman, serif",
                        fontWeight: "bold"
                      }}
                    >
                      NU KUN 199,-
                    </div>
                    <p>Dit abonnement fornyes automatisk, og du kan annullere dit abonnement efter 12 måneder.</p>
                  </div>
                </div>
                <div
                  className="subscription"
                  style={{
                    marginLeft: 80,
                    border: this.props.breaksStore.subscriber && this.props.breaksStore.subscriber.subscription === "plan-95de8" ? "4px solid #f6a23c" : "4px solid #eeeeee"
                  }}
                  onClick={() => this.selectSubscription("plan-9d7ac")}
                >
                  <div style={{
                    height: 200
                  }}>
                    <h3>Månedsabonnement</h3>
                    <p><span>Din pris per måned </span>
                      <span style={{
                        textDecoration: "line-through"
                      }}>59,-</span>
                    </p>
                    <div
                      style={{
                        color: "#ffde59",
                        fontSize: 32,
                        fontFamily: "Georgia, Times New Roman, serif",
                        fontWeight: "bold"
                      }}
                    >
                      NU KUN 19,-
                    </div>
                    <p>Dit abonnement fornyes automatisk hver måned. Du kan til en hver tid holde pause eller standse dit abonnement.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="shopWrapper">
              <div
                className="subscriptionContactInformation"
                id="UserInfo"
              >
                <div className="inputFieldLabel">Fornavn</div>
                <input
                  type="text"
                  value={this.props.breaksStore.subscriber ? this.props.breaksStore.subscriber.first_name : undefined}
                  onChange={(event: any) => {
                    this.props.breaksStore.subscriber.first_name = event.target.value;
                  }}
                />
                <div className="inputFieldLabel">Efternavn</div>
                <input
                  type="text"
                  value={this.props.breaksStore.subscriber ? this.props.breaksStore.subscriber.last_name : undefined}
                  onChange={(event: any) => {
                    this.props.breaksStore.subscriber.last_name = event.target.value;
                  }}
                />
                <div className="inputFieldLabel">Email</div>
                <input
                  type="text"
                  value={this.props.breaksStore.subscriber ? this.props.breaksStore.subscriber.email : undefined}
                  onChange={(event: any) => {
                    this.props.breaksStore.subscriber.email = event.target.value;
                    this.props.breaksStore.subscriber.handle = event.target.value;
                  }}
                />
                <div
                  className="subscriptionContactInformationButton"
                  style={{
                    opacity: this.props.breaksStore.subscriber && this.props.breaksStore.subscriber.email && this.props.breaksStore.subscriber.subscription ? 1 : 0.5,
                    cursor: this.props.breaksStore.subscriber && this.props.breaksStore.subscriber.email && this.props.breaksStore.subscriber.subscription ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (this.props.breaksStore.subscriber.email && this.props.breaksStore.subscriber.subscription) {
                      SubscriptionService.createUser(this.props.breaksStore.subscriber).then((session: ISession) => {
                        this.rp.show(session.id);
                        this.rp.addEventHandler(window.Reepay.Event.Accept, (data: any) => {
                          console.log('Success', data);
                          SubscriptionService.addSubscription(this.props.breaksStore.subscriber.subscription, this.props.breaksStore.subscriber.email, data.payment_method).then((result) => {
                            console.log('Success', result);
                            this.props.breaksStore.signUpSucces = true;
                            this.props.breaksStore.hasAccess = true;
                            this.props.breaksStore.showAccessPopup = false;
                            localStorage.setItem("hasAccess", this.props.breaksStore.subscriber.email);
                            this.rp.destroy();
                          });
                        });
                        this.rp.addEventHandler(window.Reepay.Event.Close, (data) => {
                          console.log('Close', data);
                        });
                      });
                    }
                  }}
                >
                  Opret abonnement
                </div>
              </div>
            </div>
          </div>
        }
      </div >
    );
  }

}