import * as React from 'react';
import './VideoPlayer.css';
import { inject, observer } from 'mobx-react';
import ReactPlayer from 'react-player';
import { IExercise } from '../../interfaces/IExercise';
import { BreaksStore } from '../../stores';

export interface IVideoPlayerProps {
  breaksStore?: BreaksStore;
}

@inject('breaksStore')
@observer
export default class VideoPlayer extends React.Component<IVideoPlayerProps> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      exercise: undefined,
      opacity: 0,
      isPlaying: true
    }
    this.props.breaksStore.isBuffering = true;
    setTimeout(() => {
      this.props.breaksStore.isPlaying = true;
      this.props.breaksStore.isBuffering = false;
    }, 2000);
  }

  private secondsToTime(secs): { m: string, s: string } {
    const hours = Math.floor(secs / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    const secondsAsString: string = seconds.toString().length === 1 ? "0" + seconds.toString() : seconds.toString();
    const time = {
      "m": minutes.toString(),
      "s": secondsAsString
    };
    return time;
  }

  render() {
    let duration = 0;
    if (this.props.breaksStore.selectedSerie) {
      this.props.breaksStore.selectedSerie.loadedExercises.forEach((exercise: IExercise) => {
        duration += exercise.duration;
      });
    }
    return (
      <div>
        <ReactPlayer
          className="react-player"
          width="100%"
          height="100%"
          style={{
            top: 0,
            left: 0,
            position: "absolute",
            backgroundColor: "#000000"
          }}
          url={this.props.breaksStore.selectedExercise.danish.link}
          onEnded={() => {
            if (this.props.breaksStore.selectedSerie && this.props.breaksStore.selectedExerciseIndex < this.props.breaksStore.selectedSerie.loadedExercises.length) {
              this.props.breaksStore.nextExercise();
              setTimeout(() => {
                this.props.breaksStore.isPlaying = true;
              }, 0);
            } else {
              this.props.breaksStore.selectedSerie = undefined;
              this.props.breaksStore.selectedExercise = undefined;
              this.props.breaksStore.selectedExerciseIndex = 0;
              this.props.breaksStore.playWholeSerie = false;
            }
          }}
          allowFullScreen={false}
          onPause={() => {
            this.props.breaksStore.isPlaying = false;
          }}
          onPlay={() => {
            this.props.breaksStore.isPlaying = true;
          }}
          onReady={() => {
            this.props.breaksStore.isPlaying = true;
            this.props.breaksStore.isBuffering = false;
          }}
          playing={this.props.breaksStore.isPlaying}
          onBuffer={() => this.props.breaksStore.isBuffering}
          loop={false}
          onError={() => { }}
          onClick={() => {
            this.props.breaksStore.isPlaying = !this.props.breaksStore.isPlaying;
          }}
        />
        <div className="videoLoader"
          style={{
            display: this.props.breaksStore.isBuffering ? "block" : "none"
          }}
        />
        <div
         // className="videoControls"
         className={this.props.breaksStore.isPlaying || this.props.breaksStore.isBuffering ? 'videoControls is-playing': 'videoControls'}
         
          // style={{
          //   display: this.props.breaksStore.isPlaying ? "none" : "block"

          // }}
        >
          <div
            className="backButton"
            onClick={() => {
              this.props.breaksStore.selectedSerie = undefined;
              this.props.breaksStore.selectedExercise = undefined;
              this.props.breaksStore.selectedExerciseIndex = 0;
              this.props.breaksStore.playWholeSerie = false;
            }}
          />
          <div
            className="duration"
          >
            {this.props.breaksStore.playWholeSerie ?
              `Seriens samlede varighed: ${this.secondsToTime(duration).m}:${this.secondsToTime(duration).s} - Denne films varighed: ${this.secondsToTime(this.props.breaksStore.selectedExercise.duration).m}:${this.secondsToTime(this.props.breaksStore.selectedExercise.duration).s}`
              :
              `Denne films varighed: ${this.secondsToTime(this.props.breaksStore.selectedExercise.duration).m}:${this.secondsToTime(this.props.breaksStore.selectedExercise.duration).s}`
            }
          </div>
        </div>
        <div
          className={this.props.breaksStore.isPlaying || this.props.breaksStore.isBuffering ? 'videoControls is-playing': 'videoControls'}
          // style={{
          //   display: this.props.breaksStore.isPlaying || this.props.breaksStore.isBuffering ? "none" : "block"
          // }}
        >
          {this.props.breaksStore.selectedSerie &&
            <div
              className="previousButton"
              onClick={() => {
                if (this.props.breaksStore.selectedExerciseIndex > 0) {
                  this.props.breaksStore.previousExercise();
                  setTimeout(() => {
                    this.props.breaksStore.isPlaying = true;
                  }, 0);
                }
              }}
              style={{
                opacity: this.props.breaksStore.selectedExerciseIndex === 0 ? 0.3 : 1
              }}
            />
          }
          {this.props.breaksStore.selectedSerie &&
            <div
              className="playButton"
              onClick={() => {
                this.props.breaksStore.isPlaying = true;
              }}
            />
          }
          {this.props.breaksStore.selectedSerie &&
            <div
              className="nextButton"
              onClick={() => {
                if (this.props.breaksStore.selectedExerciseIndex < this.props.breaksStore.selectedSerie.loadedExercises.length) {
                  this.props.breaksStore.nextExercise();
                  setTimeout(() => {
                    this.props.breaksStore.isPlaying = true;
                  }, 0);
                }
              }}
              style={{
                opacity: (this.props.breaksStore.selectedExerciseIndex === this.props.breaksStore.selectedSerie.loadedExercises.length - 1) ? 0.3 : 1
              }}
            />
          }
        </div>
      </div >
    );
  }

}