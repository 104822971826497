const LocalizedStrings = require('../../resources/localization.json');

export default class Localization {

  public static getString(key: string): string {
    let localizedString = "Not found: " + key;
    LocalizedStrings.forEach((translation: { key: string, Danish: string, English: string }) => {
      if (key == translation.key) {
        localizedString = translation.Danish;
        // if (navigator.language == "da-DK" || navigator.language == "da-dk" || navigator.language == "da") {
        //   localizedString = translation.Danish;
        // } else if (navigator.language == "en-GB") {
        //   localizedString = translation.English;
        // } else {
        //   localizedString = translation.English;
        // }
        return;
      }
    });
    return localizedString;
  }
}