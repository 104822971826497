import * as React from 'react';
import './DeleteCustomSeriePopup.css';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';

export interface IDeleteCustomSeriePopupProps {
  breaksStore?: BreaksStore;
}

@inject('breaksStore')
@observer
export default class DeleteCustomSeriePopup extends React.Component<IDeleteCustomSeriePopupProps> {

  render() {
    return (
      <div className="deleteCustomSeriePopupWrapper">
        <div
          className="deleteCustomSeriePopupBackground"
          onClick={() => this.props.breaksStore.customSerieToDelete = undefined}
        />
        <div className="deleteCustomSeriePopupBox">
          <div className="deleteCustomSeriePopupBoxTitle">
            Slet serie?
          </div>
          <div
            className="deleteCustomSeriePopupBoxDeleteBtn"
            onClick={() => this.props.breaksStore.deleteSerie()}
          >
            Slet
          </div>
          <div
            className="deleteCustomSeriePopupBoxCancelBtn"
            onClick={() => this.props.breaksStore.customSerieToDelete = undefined}
          >
            Fortryd
          </div>
        </div>
      </div>
    );
  }

}