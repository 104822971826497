import * as React from 'react';
import './ExerciseButton.css';
import { ISerie } from '../../../interfaces/ISerie';
import { IExercise } from '../../../interfaces/IExercise';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../../stores';

export interface IExerciseButtonProps {
  breaksStore?: BreaksStore;
  exercise: IExercise;
  index: number;
  showDeleteButton?: boolean;
  showAddButton?: boolean;
  onSelect: (selectedExercise: IExercise, index: number) => void;
  onDelete?: (index: number) => void;
  add?: (selectedExercise: IExercise) => void;
  showAccessPopup?: () => void;
}

@inject('breaksStore')
@observer
export default class ExerciseButton extends React.Component<IExerciseButtonProps> {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let name = navigator.language == "da-DK" || navigator.language == "da" || navigator.language == "da-dk" ? this.props.exercise.danish.name : (this.props.exercise.english ? this.props.exercise.english.name : "");
    return (
      <div
        className="exerciseButton"
      >
        <div
          className="exerciseButtonImage"
          style={{
            backgroundImage: `url(http://app.happybreaks.dk/images/icons/${this.props.exercise.id}.png)`
          }}
          onClick={() => {
            if (this.props.exercise.product !== "pay" || this.props.breaksStore.hasAccess) {
              this.props.onSelect(this.props.exercise, this.props.index);
            } else {
              this.props.showAccessPopup();
            }
          }}
        >
        </div>
        {this.props.showDeleteButton &&
          <div
            className="exerciseButtonDeleteBtn"
            style={{
              backgroundImage: `url(http://app.happybreaks.dk/images/delete-btn.png)`
            }}
            onClick={() => {
              if (this.props.exercise.product !== "pay" || this.props.breaksStore.hasAccess) {
                this.props.onDelete(this.props.index);
              } else {
                this.props.showAccessPopup();
              }
            }}
          />
        }
        {this.props.showAddButton && (this.props.exercise.product !== "pay" || this.props.breaksStore.hasAccess) &&
          <div
            className="exerciseButtonPlayBtn"
            style={{
              backgroundImage: `url(http://app.happybreaks.dk/images/small-add-btn.png)`
            }}
            onClick={() => {
              this.props.add(this.props.exercise);
            }}
          />
        }
        {this.props.exercise.product === "pay" && !this.props.breaksStore.hasAccess &&
          <div
            className="exerciseButtonPlayBtn"
            style={{
              backgroundImage: `url(http://app.happybreaks.dk/images/lock.png)`
            }}
            onClick={() => {
              this.props.showAccessPopup();
            }}
          />
        }
        <div
          className="exerciseButtonText"
          onClick={() => {
            if (this.props.exercise.product !== "pay" || this.props.breaksStore.hasAccess) {
              this.props.onSelect(this.props.exercise, this.props.index);
            } else {
              this.props.showAccessPopup();
            }
          }}
        >
          {name}
        </div>
      </div>
    );
  }

}