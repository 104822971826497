import * as React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import { BreaksStore } from './stores';
import { Provider } from 'mobx-react';

declare global {
  interface Window { app: any; Reepay: any }
}

render(
  <Provider breaksStore={new BreaksStore()}>
    <App ref={(app: any) => { window.app = app.wrappedInstance }} />
  </Provider>,
  document.getElementById('root')
);