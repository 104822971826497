import * as React from 'react';
import './AccessPopup.css';
import { inject, observer } from 'mobx-react';
import { BreaksStore } from '../../stores';
import SubscriptionService from '../../services/SubscriptionService';

export interface IAccessPopupProps {
  breaksStore?: BreaksStore;
}

@inject('breaksStore')
@observer
export default class AccessPopup extends React.Component<IAccessPopupProps> {

  render() {
    return (
      <div className="accessPopupWrapper">
        <div
          className="accessPopupBackground"
          onClick={() => {
            this.props.breaksStore.showAccessPopup = false;
            this.props.breaksStore.notActiveUser = false;
          }}
        />
        <div className="accessPopupBox">
          <div className="accessPopupTitle">
            Fuld adgang
          </div>

          <input
            id="AccessEmailInputField"
            type="text"
            placeholder={"Email eller kode"}
            value={this.props.breaksStore.email}
            style={{
              color: this.props.breaksStore.notActiveUser ? "red" : "#333333"
            }}
            onChange={() => {
              const inputField: any = document.getElementById("AccessEmailInputField");
              this.props.breaksStore.notActiveUser = false;
              this.props.breaksStore.email = inputField.value;
            }}
          />
          <div
            className="accessBtn"
            onClick={() => {
              SubscriptionService.get(this.props.breaksStore.email).then((result) => {
                console.log(result);
                if (result && result.content && result.content.length > 0 && result.content[0].state === "active") {
                  localStorage.setItem("hasAccess", this.props.breaksStore.email);
                  this.props.breaksStore.email = "";
                  this.props.breaksStore.hasAccess = true;
                  this.props.breaksStore.showAccessPopup = false;
                  this.props.breaksStore.notActiveUser = false;
                } else {
                  this.props.breaksStore.notActiveUser = true;
                  this.forceUpdate();
                }
              });
            }}
          >
            Adgang
          </div>
          <div className="accessLink">
            Tilmeld dig her:
            {/* <a href="https://app.happybreaks.dk/?shop=true">
              <div className="accessLinkBtn" />
            </a> */}
            <div className="accessLinkBtn"
              onClick={() => this.props.breaksStore.showShop = true}
            />

          </div>
        </div>
      </div>
    );
  }

}